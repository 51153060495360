import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Section from 'components/Section'
import Text from 'components/Container/text'
import media from 'styles/media'
import Link from 'components/Link'
import { black, pink } from 'const/colors'
import PageTransitionLink from 'components/PageTransitionLink'
import { animatedLinkStyles } from 'styles/animations'

const Title = styled.p`
  opacity: 0.5;
  color: ${black};
  font-size: 20px;
  line-height: 28px;

  ${media.tablet`
    font-size: 18px;
    margin-bottom: 10px;
  `}
`

const TextStyles = css`
  p {
    color: ${black};
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;

    ${media.tablet`
    font-size: 20px;
    line-height: 28px
    margin: 0;
  `}
  }
`

const View = styled.div`
  margin-top: 40px;

  ${media.tablet`
    margin-top: 10px;
  `}
`

const ExternalLinkStyles = styled(Link)`
  font-weight: bold;

  &::after {
    background: ${pink};
  }
`

const LinkStyles = styled.span`
  font-weight: bold;
  ${animatedLinkStyles}

  &::after {
    background: ${pink};
  }
`

const ServiceInfo = ({
  title,
  description,
  slug,
  projectLink,
  linkType,
  linkText,
}) => {
  return (
    <Section>
      <Title>{title}</Title>
      <Text richText textStyles={TextStyles}>
        {description}
      </Text>
      <View>
        {linkType && linkType.includes('url') ? (
          <ExternalLinkStyles
            size={16}
            animatedLink
            externalLink
            to={projectLink}
          >
            {linkText}
          </ExternalLinkStyles>
        ) : (
          <PageTransitionLink to={`/work/${slug}`}>
            <LinkStyles>{linkText}</LinkStyles>
          </PageTransitionLink>
        )}{' '}
        →
      </View>
    </Section>
  )
}

export default ServiceInfo

ServiceInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  linkText: PropTypes.string,
  slug: PropTypes.string,
  projectLink: PropTypes.string,
  linkType: PropTypes.string,
}
