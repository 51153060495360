import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Section from 'components/Section'
import media from 'styles/media'
import ProjectText from './projectText'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

const Photo = styled.img`
  height: 300px;
  width: auto;
  display: block;
  margin: 0 auto;

  ${media.tablet`
    height: 192px;
  `}
`

const ScrollAnimationStyled = styled(ScrollAnimation)`
  width: 100%;
`

const ProjectInfo = ({
  title,
  description,
  slug,
  projectLink,
  linkType,
  image,
}) => {
  const srcSmall = `${image}?w=562`
  const srcMedium = `${image}?w=840`
  return (
    <Section width={[1, 1 / 2]} mb={100} px={2}>
      <LazyLoad offset={100}>
        <ScrollAnimationStyled animateIn="fadeIn">
          <picture>
            <source
              srcSet={srcSmall}
              media="(min-width: 0px) and (max-width: 1279px) and (min-resolution: 190dpi)"
            ></source>
            <source
              srcSet={srcMedium}
              media="(min-width: 1280px) and (min-resolution: 190dpi)"
            ></source>
            <source srcSet={image}></source>
            <Photo src={image} />
          </picture>
        </ScrollAnimationStyled>
      </LazyLoad>
      {renderText(title, description, slug, projectLink, linkType)}
    </Section>
  )
}

const renderText = (title, description, slug, projectLink, linkType) => (
  <ProjectText
    title={title}
    description={description}
    slug={slug}
    projectLink={projectLink}
    linkType={linkType}
  />
)

export default ProjectInfo

ProjectInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  photo: PropTypes.string,
  image: PropTypes.string,
  projectLink: PropTypes.string,
  linkType: PropTypes.string,
}
