import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'layouts/Default'
import MoreWorkSection from 'sections/Work/more'

const Index = (props) => {
  const buildOGMetadata = () => {
    const { elements } = props.data.kenticoCloudItemWork

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }

  return (
    <Layout {...props} ogMetadata={buildOGMetadata()}>
      <MoreWorkSection {...props} />
    </Layout>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  {
    kenticoCloudItemWork {
      elements {
        projects {
          elements {
            title {
              value
            }
            preview_image {
              value {
                url
              }
            }
            preview_description {
              value
            }
            slug {
              value
            }
            project_link {
              value
            }
            link_text {
              value
            }
            link_type {
              value {
                codename
              }
            }
          }
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
  }
`
