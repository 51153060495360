import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import PageSection from 'components/Section/pageSection'
import Container from 'components/Container'
import Headline from 'components/Headline'
import media from 'styles/media'
import ProjectInfo from './components/projectInfoVertical'

const WorkContainer = styled(Container)`
  padding-top: 100px;

  ${media.tablet`
    padding-top: 66px;
  `}
`

const renderProjectInfos = (projects) => {
  return (
    <Flex mx={-2} mt={50} flexWrap="wrap">
      {projects.map(
        (
          {
            elements: {
              title,
              preview_description,
              slug,
              preview_image,
              project_link,
              link_type: { value: linkType },
            },
          },
          i
        ) => (
          <ProjectInfo
            title={title.value}
            description={preview_description.value}
            slug={slug.value}
            projectLink={project_link.value}
            linkType={linkType[0].codename}
            image={preview_image.value[0].url}
            key={i}
          />
        )
      )}
    </Flex>
  )
}

const MoreWorkSection = (props) => {
  const projects = props.data.kenticoCloudItemWork.elements.projects
  return (
    <PageSection>
      <WorkContainer>
        <Headline>More work</Headline>
        {renderProjectInfos(projects)}
      </WorkContainer>
    </PageSection>
  )
}

export default MoreWorkSection

MoreWorkSection.propTypes = {
  data: PropTypes.object,
}
